<div
  style="background-image: url(./../../../assets/img/REU_Bosch_KitchenConcept_L-Shape_B.png)"
  class="flex w-full items-end bg-cover bg-no-repeat p-8 md:h-[37rem]"
>
  <div class="max-w-xl bg-white p-6 text-black">
    <h1 class="mb-4 text-2xl font-bold leading-tight md:text-5xl">Das Hausgeräte-Programm</h1>
    <p class="font-bosch">
      Intelligent, innovativ und immer da, wenn du sie brauchst: Bosch Hausgeräte sind treue Begleiter, die dein Leben
      jeden Tag ein bisschen einfacher machen. Finde die richtigen Geräte für dein Zuhause.
    </p>
  </div>
</div>

<div [class.opacity-50]="isLoading" [class.pointer-events-none]="isLoading">
  <app-filter-checkboxes [styleClasses]="'mt-4'"></app-filter-checkboxes>
</div>

<h2 class="my-12 text-2xl">
  Das Solo- und Einbaugeräte-Programm 2023/2024 <br />
  Nur für den Elektro-Fachhandel
</h2>

<div class="grid grid-cols-1 gap-4 text-xl md:grid-cols-2 lg:grid-cols-4">
  @for (product of productsStructure; track product) {
    <div class="flex flex-col gap-4">
      <h2>{{ product.name }}</h2>
      <img
        [src]="productsImageStructure[product.name]"
        alt="{{ product.name }}"
        class="h-32 w-full object-cover object-center"
      />

      <ul class="grid grid-cols-1 gap-4">
        @for (child of product.children; track child) {
          @if (isLoading) {
            <li
              class="animate-pulse cursor-pointer bg-bosch-grey-light p-4 text-gray-700"
              (click)="navigateToProductOverview(child.filters)"
            >
              {{ child.name }}
            </li>
          } @else {
            <li
              class="cursor-pointer bg-bosch-grey-light p-4"
              (click)="navigateToProductOverview(child.filters)"
              [class.opacity-50]="disableAllCategories"
              [class.pointer-events-none]="disableAllCategories"
            >
              <!-- [class.opacity-50]="!productAvailability[child.name]"
            [class.pointer-events-none]="!productAvailability[child.name]" -->
              {{ child.name }}
            </li>
          }
        }
      </ul>
    </div>
  }
</div>
