import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { IProductListApiResponse, IProdApiResponse, IProdsApiResponse } from '../models/apiResponse.model';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private readonly headers = new HttpHeaders().set('Content-Type', 'application/json');
  private readonly brandId = 'A01';

  constructor(private http: HttpClient) {}

  /**
   * Searches for a specific product with all its features by either a vib or an ean number
   * @param vib (optional) contains the vib number of a product
   * @param ean (optional) contains the ean of a product
   * @returns the JSON file of a specific product (or 404 if no product can be found)
   */

  /**
   * Searches for a specific product with all its features by specific category url, query params or both
   * @param filterSlug contains the filter Slug of that product category
   * @returns the JSON file of a specific product (or 404 if no product can be found)
   */
  getProductBySearchParams(
    filterSlug: string,
    page: number = 1,
    perPage: number = 5,
    with_details: string = '',
  ): Observable<IProductListApiResponse> {
    const url = `${environment.apiUrl}products?brandId=${this.brandId}&${filterSlug}&page=${page}&per_page=${perPage}&with_details=${with_details}`;
    return this.http.get<IProductListApiResponse>(url, { headers: this.headers });
  }

  /**
   * Searches for more products with all its features by their vibs
   * @param vibs (optional) contains the combination of vib numbers of a products array
   * @returns the JSON file of a group of products (or 404 if no product can be found)
   */
  getProducts(vibs: string[], salesprogram: string[] | void): Observable<IProdsApiResponse> {
    return this.http.get<IProdsApiResponse>(
      environment.apiUrl +
        `products?brandId=${this.brandId}&sales_program=${salesprogram}&identifier=${vibs.join(',')}&with_details=true`,
      {
        headers: this.headers,
      },
    );
  }

  getProductBySearchParamsNoDetailsNoPages(filterSlug: string): Observable<IProductListApiResponse> {
    const url = `${environment.apiUrl}products?brandId=${this.brandId}&${filterSlug}`;
    return this.http.get<IProductListApiResponse>(url, { headers: this.headers });
  }
}
