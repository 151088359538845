<div class="absolute left-0 top-44 h-12 w-full bg-bosch-grey-light"></div>
<div class="mt-16">
  <app-filter-checkboxes></app-filter-checkboxes>
  <h1 class="mb-6 mt-6 text-4xl">{{ pageTitle }}</h1>
  <div class="mb-6 flex items-center justify-between">
    @if (meta.total && meta.total > 0) {
      <span class="font-bosch">{{ meta.total }} Produkte</span>
    } @else {
      <span class="font-bosch">Keine Produkte gefunden. Bitte wählen Sie mindestens ein Sales Program.</span>
    }
    <app-product-pagination [meta]="meta"></app-product-pagination>
    <p></p>
  </div>

  <div class="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-6">
    @for (product of productList.data; track product) {
      <div class="border-b-[1px] border-b-gray-200 font-bosch md:border-b-0">
        <app-product-card [product]="product" [categoryTitle]="cartTitle"></app-product-card>
      </div>
    }
    @if (productsToCompare.length > 0 && productList.data && productList.data.length > 0) {
      <div class="col-span-1 p-4 md:col-start-2 lg:col-start-6">
        <p class="pb-2">{{ cartTitle }}</p>
        @for (prodComp of productsToCompare; track prodComp) {
          <div class="flex flex-col border-t-[1px] border-t-black py-2">
            <div class="flex items-center justify-between">
              <div>
                <p class="font-bosch text-sm">{{ prodComp.vib }}</p>
              </div>
              <div class="size-4">
                <img
                  class="cursor-pointer"
                  src="./../../../assets/icons/close.svg"
                  (click)="onClickDeleteProduct(prodComp)"
                />
              </div>
            </div>
            <p class="text-sm">{{ prodComp.title }}</p>
          </div>
        }
        @if (productsToCompare.length >= 2) {
          <div
            class="mt-3 flex w-full cursor-pointer items-center justify-center bg-bosch-blue-light px-3 py-1 font-bosch text-white"
          >
            <span class="inline-flex items-center justify-center" [routerLink]="['/vergleich']"
              >Produkte vergleichen</span
            >
          </div>
        }
      </div>
    }
  </div>
  <div class="mt-6 lg:hidden">
    <app-product-pagination [meta]="meta"></app-product-pagination>
  </div>
</div>
