import { Component, Input } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { CommonModule } from '@angular/common';
import { IPaginationMeta, IProductListApiResponse } from '../../models/apiResponse.model';
import { ProductCardComponent } from '../../components/product-card/product-card.component';
import { productsStructure } from '../../../assets/data/products-structure';
import { FilterCheckboxesComponent } from '../../components/filter-checkboxes/filter-checkboxes.component';
import { CompareService } from '../../services/compare.service';
import { ShortProductData } from '../../models/product.model';
import { HelperService } from '../../services/helper.service';
import { SalesProgramService } from '../../services/sales-program-filter.service';
import { Subscription } from 'rxjs';
import { ProductPaginationService } from '../../services/product-pagination.service';
import { ProductPaginationComponent } from '../../components/product-pagination/product-pagination.component';

@Component({
  selector: 'app-products',
  standalone: true,
  imports: [CommonModule, ProductCardComponent, FilterCheckboxesComponent, RouterModule, ProductPaginationComponent],
  templateUrl: './product-overview.component.html',
  styleUrl: './product-overview.component.scss',
})
export class ProductOverviewComponent {
  private pageChangeSubscription = new Subscription();
  urlFilterSlug: string | null = null;
  productList: IProductListApiResponse = {} as IProductListApiResponse;
  meta: IPaginationMeta = {} as IPaginationMeta;
  pageTitle = 'Produkte';
  productsToCompare: ShortProductData[] = [];
  cartTitle = 'Vergleichen';
  salesProgram: string[] = [];

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private compareService: CompareService,
    private helperService: HelperService,
    private productPaginationService: ProductPaginationService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.salesProgram = params['sales_program'];
      if (this.salesProgram) {
        this.urlFilterSlug = params['filter'] + '&sales_program=' + params['sales_program'];
      } else {
        this.urlFilterSlug = params['filter'];
      }
      this.applyFilterAndFetchProducts();
    });

    this.getCartAndPageTitleByUrlFilterSlug();

    this.compareService.getProductsByCategoryTitle(this.cartTitle).subscribe((products) => {
      this.productsToCompare = products;
    });

    this.pageChangeSubscription = this.productPaginationService.pageChange$.subscribe((page) => {
      this.applyFilterAndFetchProducts(page);
    });
  }

  ngOnDestroy(): void {
    this.pageChangeSubscription.unsubscribe();
  }

  applyFilterAndFetchProducts(page: number = 1) {
    if (!this.urlFilterSlug) return;
    if (!this.salesProgram) {
      this.productList = {} as IProductListApiResponse;
      this.meta = {} as IPaginationMeta;
      return;
    } else {
      this.apiService.getProductBySearchParams(this.urlFilterSlug, page, 5, 'true').subscribe((data) => {
        this.productList = data;
        this.meta = data.meta;
      });
    }
  }

  onClickDeleteProduct(product: ShortProductData) {
    this.compareService.removeProductFromCart(this.cartTitle, product);
  }

  getCartAndPageTitleByUrlFilterSlug() {
    if (this.helperService.isLocalStorageAvailable()) {
      if (this.urlFilterSlug) {
        for (const parent of productsStructure) {
          for (const child of parent.children) {
            const childSlug = this.helperService.createApiFilterParams(child.filters);
            if (this.urlFilterSlug.includes(childSlug)) {
              this.pageTitle = child.name;
              this.cartTitle = parent.name;
              localStorage.setItem('selectedCategory', this.cartTitle);
            }
          }
        }
      }
    }
  }
}
