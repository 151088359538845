<footer>
  <div class="mt-20 flex justify-between">
    <p class="font-bosch text-3xl">Technik fürs Leben</p>

    <div class="flex items-center">
      <div class="flex gap-4">
        <a href="https://www.facebook.com/BoschHomeDeutschland"
          ><img class="size-6" src="./../../../assets/icons/facebook.svg" alt="facebook"
        /></a>
        <a href="https://www.youtube.com/user/boschhomedeutschland"
          ><img class="size-6" src="./../../../assets/icons/youtube.svg" alt="youtube"
        /></a>
        <a href="https://www.instagram.com/boschhomede"
          ><img class="size-6" src="./../../../assets/icons/instagram.svg" alt="instagram"
        /></a>
        <a href="https://www.pinterest.de/boschhomede"
          ><img class="size-6" src="./../../../assets/icons/pinterest.svg" alt="pinterest"
        /></a>
      </div>
    </div>
  </div>
  <div class="blex mt-8 flex flex-wrap gap-6 border-t border-black pb-8 pt-4 font-bosch text-xs text-black">
    <p>© Robert Bosch Hausgeräte GmbH</p>
    <a href="https://www.bosch-home.com/de/metaseiten/impressum">Impressum/Informationen zum Datenschutz</a>
    <a href="https://www.bosch-home.com/de/produkte/allgemeine-geschaeftsbedingungen">AGB & Widerrufsbelehrung</a>
    <a href="https://www.bosch-home.com/de/service/garantie">Garantiebedingungen</a>
    <a href="https://www.bosch-home.com/de/pressecenter">Presse-Center</a>
    <a href="https://www.bosch-home.com/de/service/kontakt-und-information/newsletter-registrieren">Newsletter</a>
    <a href="https://www.bosch-home.com/de/service/prospekte">Prospekte</a>
  </div>
</footer>
