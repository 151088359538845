import { Component } from '@angular/core';
import { FilterCheckboxesComponent } from '../../components/filter-checkboxes/filter-checkboxes.component';
import {
  productsStructure,
  productsImageStructure,
  ProductFilter,
  ProductChild,
} from '../../../assets/data/products-structure';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { HelperService } from '../../services/helper.service';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { ApiService } from '../../services/api.service';
import { SalesProgramService } from '../../services/sales-program-filter.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  standalone: true,
  imports: [FilterCheckboxesComponent, RouterModule],
})
export class HomeComponent {
  private salesProgramCheckboxSubscription = new Subscription();
  productsStructure = productsStructure;
  productsImageStructure = productsImageStructure;
  checkboxQueryParams = '';
  isLoading = false;
  productAvailability: { [key: string]: boolean } = {};
  disableAllCategories = false;

  constructor(
    private router: Router,
    private helperService: HelperService,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private salesProgramService: SalesProgramService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const urlParams = params['sales_program'] || null;
      this.checkboxQueryParams = this.helperService.parseUrlParamToString(urlParams);
      this.disableAllCategories = false;
      if (this.checkboxQueryParams && this.helperService.isLocalStorageAvailable()) {
        localStorage.setItem('sales_program', this.checkboxQueryParams);

        this.loadSubcategoriesToCheckProductAvailability(this.checkboxQueryParams.split(','));
      } else if (this.checkboxQueryParams == null) {
        this.productAvailability = {};
      } else {
        this.disableAllCategories = true;
      }
    });
  }

  loadSubcategoriesToCheckProductAvailability(selectedSalesPrograms: string[]): void {
    // this.isLoading = true; // Set loading state to true
    // const observables: Observable<any>[] = [];
    // const childIdentifiers: { categoryIndex: number; childIndex: number }[] = [];
    // // Collect filters and identifiers for each child
    // this.productsStructure.forEach((category, categoryIndex) => {
    //   category.children.forEach((child, childIndex) => {
    //     const filters = { ...child.filters, sales_program: selectedSalesPrograms };
    //     const filterSlug = this.helperService.createApiFilterParams(filters);
    //     // Push the observable for the API call
    //     observables.push(this.apiService.getProductBySearchParamsNoDetailsNoPages(filterSlug));
    //     // Keep track of the category and child indices
    //     childIdentifiers.push({ categoryIndex, childIndex });
    //   });
    // });
    // // Execute all observables in parallel
    // forkJoin(observables).subscribe({
    //   next: (results) => {
    //     results.forEach((result, index) => {
    //       const { categoryIndex, childIndex } = childIdentifiers[index];
    //       const child = this.productsStructure[categoryIndex].children[childIndex];
    //       // If no products are available, mark the child as disabled
    //       this.productAvailability[child.name] = result.data.length > 0;
    //     });
    //   },
    //   error: (error) => {
    //     console.error('Error:', error);
    //   },
    //   complete: () => {
    //     this.isLoading = false;
    //   },
    // });
  }

  navigateToProductOverview(filter: ProductFilter): void {
    const filterSlug = this.helperService.createApiFilterParams(filter);
    const salesProgram = this.helperService.getSalesProgramFilters()?.join(',');

    this.router.navigate(['/produkt-uebersicht'], { queryParams: { filter: filterSlug, sales_program: salesProgram } });
  }

  ngOnDestroy(): void {
    this.salesProgramCheckboxSubscription.unsubscribe();
  }
}
