import { Component, ElementRef, QueryList, ViewChildren } from '@angular/core';

import { CompareService } from '../../services/compare.service';
import { FullProductData, ShortProductData } from '../../models/product.model';
import { ApiService } from '../../services/api.service';

import { ProductDetail } from '../../models/product-detail.model';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-product-comparison',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './product-comparison.component.html',
  styleUrls: ['./product-comparison.component.scss'],
})
export class ProductComparisonComponent {
  @ViewChildren('productsContainer') productsContainer?: QueryList<ElementRef>;
  @ViewChildren('accordionContainer') accordionContainer?: QueryList<ElementRef>;

  public categories = [] as string[];
  public selectedCategory = '';
  public productsToCompare: FullProductData[] = [];
  public mappedProductDetails: ProductDetail[] = [];
  public openAccordions: Set<string> = new Set();
  private vibs: string[] = [];

  private scrollPosition = 0;

  private readonly STORAGE_KEY = 'comparedProducts';
  private subscription = new Subscription();

  constructor(
    private compareService: CompareService,
    private apiService: ApiService,
    public helperService: HelperService,
  ) {}

  ngOnInit(): void {
    this.loadAvailableCartCategories();
    if (typeof localStorage !== 'undefined') {
      this.selectedCategory = localStorage.getItem('selectedCategory') || this.categories[0];
    }
    this.loadProductsToCompare(this.selectedCategory);
  }

  public syncScroll(event: Event): void {
    const sourceElement = event.target as HTMLElement;
    this.scrollPosition = sourceElement.scrollLeft;

    this.productsContainer?.forEach((container) => {
      container.nativeElement.scrollLeft = this.scrollPosition;
    });
    this.accordionContainer?.forEach((container) => {
      container.nativeElement.scrollLeft = this.scrollPosition;
    });
  }

  private mapProductDetails(products: FullProductData[]): ProductDetail[] {
    const result = new Map<string, string[][]>();

    for (const product of products) {
      for (const detail of product.details) {
        if (!result.has(detail.headline)) {
          result.set(detail.headline, []);
        }

        result.get(detail.headline)!.push(detail.lines);
      }
    }

    return Array.from(result, ([headline, lines]) => ({ headline, lines }));
  }

  public toggleAccordion(headline: string) {
    if (this.openAccordions.has(headline)) {
      this.openAccordions.delete(headline);
    } else {
      this.openAccordions.add(headline);

      // scrolls to position of other containers
      requestAnimationFrame(() => {
        this.accordionContainer?.forEach((container) => {
          container.nativeElement.scrollLeft = this.scrollPosition;
        });
      });
    }
  }

  public isOpen(headline: string): boolean {
    return this.openAccordions.has(headline);
  }

  public removeVibAndGetProducts(vib: string): void {
    const productCategoriesJson = JSON.parse(localStorage.getItem(this.STORAGE_KEY)!);

    if (productCategoriesJson) {
      productCategoriesJson[this.selectedCategory] = productCategoriesJson[this.selectedCategory].filter(
        (product: ShortProductData) => product.vib !== vib,
      );
      //remove product from cart
      const productToRemove = this.productsToCompare.filter((product) => product.vib === vib);
      if (productToRemove) {
        const shortProductData: ShortProductData = {
          title: productToRemove[0].title,
          vib: productToRemove[0].vib,
          ean: productToRemove[0].ean,
        };
        this.compareService.removeProductFromCart(this.selectedCategory, shortProductData);
      }

      //remove product from compare list
      this.vibs = this.vibs.filter((v) => v !== vib);
      this.productsToCompare = this.productsToCompare.filter((product) => product.vib !== vib);
      this.mappedProductDetails = this.mapProductDetails(this.productsToCompare);
    }
  }

  public changeCart(event: Event): void {
    this.selectedCategory = (event.target as HTMLSelectElement).value;
    localStorage.setItem('selectedCategory', this.selectedCategory);

    this.loadProductsToCompare(this.selectedCategory);
  }

  private loadProductsToCompare(categoryTitle: string): void {
    if (this.helperService.isLocalStorageAvailable()) {
      const productCategoriesJson = JSON.parse(localStorage.getItem(this.STORAGE_KEY)!);

      if (productCategoriesJson) {
        const vibs = productCategoriesJson[categoryTitle].map((product: ShortProductData) => product.vib);

        this.getProductsByVibs(vibs);
      }
    }
  }

  private getProductsByVibs(vibs: string[]): void {
    this.subscription.add(
      this.apiService.getProducts(vibs, this.helperService.getSalesProgramFilters()).subscribe({
        next: (res) => {
          this.productsToCompare = res.data;
          this.mappedProductDetails = this.mapProductDetails(res.data);
        },
        error: (err) => {
          console.error(err);
        },
      }),
    );
  }

  private loadAvailableCartCategories(): void {
    this.categories = this.compareService.getProductCategoriesInCart();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
