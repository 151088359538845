import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullProductData, ShortProductData } from '../../models/product.model';
import { CompareService } from '../../services/compare.service';
import { Observable, Subscription, fromEvent, map } from 'rxjs';
import { RouterModule } from '@angular/router';
import { SalesProgram } from '../../models/product.model';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-product-card',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent implements OnInit, OnDestroy {
  @Input({ required: true }) product!: FullProductData;
  @Input({ required: true }) categoryTitle!: string;
  cbCompareProduct = false;
  private subscription = new Subscription();

  constructor(
    private compareService: CompareService,
    public helperService: HelperService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.compareService.getProductsByCategoryTitle(this.categoryTitle).subscribe((products) => {
        this.cbCompareProduct = products.some((p) => p.ean === this.product.ean);
      }),
    );
  }

  onCheckboxChange() {
    const shortProduct: ShortProductData = {
      title: this.product.title,
      ean: this.product.ean,
      vib: this.product.vib,
    };

    if (this.cbCompareProduct) {
      this.compareService.removeProductFromCart(this.categoryTitle, shortProduct);
    } else {
      this.compareService.addProductToCart(this.categoryTitle, shortProduct);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
