<div class="mt-6 flex cursor-pointer items-center" (click)="goBack()">
  <span class="inline-flex items-center justify-center bg-bosch-blue-light px-5 py-[6px] font-bosch text-white">
    <img
      class="ml-[-15px] size-8 -rotate-180 invert"
      src="/../../../assets/icons/wide-angled-arrow.svg"
    />Produktübersicht</span
  >
</div>
<div
  class="loadbeeTabContent"
  [attr.data-loadbee-apikey]="'wmwb7Kkf3up5dKByJJ3TyzHAfB5VmvPG'"
  [attr.data-loadbee-gtin]="ean"
  [attr.data-loadbee-locale]="'de_DE,en_GB,fr_FR'"
>
  Details:
</div>
