import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { IFilterCheckbox } from '../../models/filter-checkbox.model';
import { CompareService } from '../../services/compare.service';
import { SalesProgramService } from '../../services/sales-program-filter.service';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-filter-checkboxes',
  standalone: true,
  imports: [],
  templateUrl: './filter-checkboxes.component.html',
  styleUrls: ['./filter-checkboxes.component.scss'],
})
export class FilterCheckboxesComponent implements OnInit {
  @Input() styleClasses: string = '';

  checkBoxes: IFilterCheckbox[] = [
    { name: 'DLN', label: 'Gesamtsortiment', checked: false },
    { name: 'DMK', label: 'EXCLUSIV®', checked: false },
    { name: 'DFH', label: 'Full House', checked: false },
  ];
  parsedParams: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private compareService: CompareService,
    private checkboxService: SalesProgramService,
    private helperService: HelperService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const urlParams = params['sales_program'] || null;
      this.parsedParams = this.helperService.parseUrlParamToString(urlParams);
    });

    const filter = this.getSalesProgramFilterFromUrl();
    if (filter) {
      this.checkCheckboxesAndSaveToLocalStorage(filter);
    }

    if (!filter) {
      const couldBeLoadedFromLocalStorage = this.loadSalesProgramFilterFromLocalStorage();

      if (!couldBeLoadedFromLocalStorage) {
        this.setDefaultCheckboxes();
      }
    }
  }

  setDefaultCheckboxes() {
    this.checkBoxes.forEach((checkbox) => {
      checkbox.checked = true;
    });

    const selectedSalesPrograms = this.checkBoxes
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.name);
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { sales_program: selectedSalesPrograms.join(',') },
      queryParamsHandling: 'merge',
    });
  }

  getSalesProgramFilterFromUrl(): string | null {
    const params = this.route.snapshot.queryParams;
    let salesProgram = params['sales_program'];
    if (Array.isArray(salesProgram)) {
      salesProgram = salesProgram.join(',');
    }
    return salesProgram;
  }

  checkCheckboxesAndSaveToLocalStorage(filter: string) {
    const filterArray = filter.split(',');
    if (this.helperService.isLocalStorageAvailable()) {
      localStorage.setItem('sales_program', filterArray.join(','));
    }
    this.checkBoxes.forEach((checkbox) => {
      checkbox.checked = filterArray.includes(checkbox.name);
    });
  }

  loadSalesProgramFilterFromLocalStorage(): boolean {
    // Checkboxes are not in URL -> Load the filter from the local storage
    let storedFilter: string | null = null;
    if (this.helperService.isLocalStorageAvailable()) {
      storedFilter = localStorage.getItem('sales_program');
    }

    const currentParams = { ...this.route.snapshot.queryParams };

    if (storedFilter) {
      const storedFilterArray = storedFilter.split(',');
      this.checkBoxes.forEach((checkbox) => {
        checkbox.checked = storedFilterArray.includes(checkbox.name);
      });
      currentParams['sales_program'] = storedFilterArray.join(',');
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: currentParams,
        queryParamsHandling: 'merge',
      });
      return true;
    }

    if (storedFilter === null || storedFilter === '') {
      // If the page was called the first time in this browser or checkboxes have been manually unchecked
      delete currentParams['sales_program'];

      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: currentParams,
        queryParamsHandling: 'merge',
      });
      return storedFilter !== null; // Return true if storage key was available (manually unchecked), false otherwise
    }

    return false;
  }

  /**
   * Handles the checkbox change event.
   *
   * @param event - The event triggered by the checkbox change.
   * @param checkbox - The checkbox that was changed.
   */
  changeAndStoreUrlQueryParams(event: Event, checkbox: IFilterCheckbox) {
    // Update the checkbox's checked status
    checkbox.checked = (event.target as HTMLInputElement).checked;

    // Get the checked checkboxes and their names
    const checkedCheckboxes = this.checkBoxes.filter((cb) => cb.checked);
    const selectedSalesPrograms = checkedCheckboxes.map((cb) => cb.name);

    // Store the selected filters in local storage and in the URL
    if (this.helperService.isLocalStorageAvailable()) {
      localStorage.setItem('sales_program', selectedSalesPrograms.join(','));
    }

    this.changeRouteQueryParams(checkedCheckboxes, selectedSalesPrograms);
    // Emit the change to other components
    this.checkboxService.emitCheckboxChange(selectedSalesPrograms);
  }
  changeRouteQueryParams(checkedCheckboxes: IFilterCheckbox[], selectedSalesPrograms: string[]) {
    const currentParams = { ...this.route.snapshot.queryParams };

    if (checkedCheckboxes.length === 0) {
      // If no checkboxes are checked, delete sales_program from the query params
      delete currentParams['sales_program'];

      // Navigate with updated queryParams without using 'merge'
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: currentParams,
      });
    } else {
      // If checkboxes are checked, add or update the sales_program query param
      currentParams['sales_program'] = selectedSalesPrograms.join(',');

      // Navigate with updated queryParams without using 'merge'
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: currentParams,
        queryParamsHandling: 'merge',
      });
    }
  }
}
