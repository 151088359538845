import { Component, Input } from '@angular/core';
import { IPaginationMeta } from '../../models/apiResponse.model';
import { ProductPaginationService } from '../../services/product-pagination.service';

@Component({
  selector: 'app-product-pagination',
  standalone: true,
  imports: [],
  templateUrl: './product-pagination.component.html',
  styleUrl: './product-pagination.component.scss',
})
export class ProductPaginationComponent {
  @Input({ required: true }) meta: IPaginationMeta = {} as IPaginationMeta;
  constructor(private paginationService: ProductPaginationService) {}
  getPaginationItems() {
    const pages = [];
    const maxPages = 4; // Maximum pages to display at once
    const maxElements = 5; // Elements per page

    // Calculate total pages needed based on total elements and max elements per page
    const totalPages = Math.ceil(this.meta.total / maxElements);

    // If total pages are less than or equal to max pages, show all pages
    if (totalPages <= maxPages) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Handle case when there are more pages than max pages to display
      if (this.meta.current_page <= 2) {
        // If current page is near the beginning, show the first 4 pages
        for (let i = 1; i <= maxPages; i++) {
          pages.push(i);
        }
      } else if (this.meta.current_page >= totalPages - 1) {
        // If current page is near the end, show the last 4 pages
        for (let i = totalPages - (maxPages - 1); i <= totalPages; i++) {
          pages.push(i);
        }
      } else {
        // Otherwise, show the current page and pages around it
        pages.push(this.meta.current_page - 1);
        pages.push(this.meta.current_page);
        pages.push(this.meta.current_page + 1);
        pages.push(this.meta.current_page + 2);
      }
    }

    return pages;
  }

  changePage(page: number | string) {
    if (typeof page === 'number' && page !== this.meta.current_page) {
      this.paginationService.emitPageChange(page);
    }
  }
}
