export interface EnergyLabel {
  id: number;
  asset_id: string;
  type: string;
  category: string;
  url: string;
}

export interface Detail {
  headline: string;
  lines: string[];
}

export interface RRP {
  amount: string;
  currency: string;
  formatted: string;
}

export interface Highlight {
  id: number;
  title: string;
  headline: string;
  key_benefit_text: string;
  short_text: string;
  long_text: string;
  assets: Asset[];
}

export interface Asset {
  id: number;
  asset_id: string;
  type: string;
  category: string;
  url: string;
}

export interface Accessory {
  vib: string;
  ean: string;
  lowResPicture: string;
  medResPicture: string;
  highResPicture: string;
  title: string;
  uspMain: string;
}

export interface FullProductData {
  vib: string;
  ean: string;
  lowResPicture: string;
  medResPicture: string;
  highResPicture: string;
  title: string;
  countryOfOrigin: string;
  madeInGermanyIcon?: MadeInGermanyIcon;
  uspMain: string;
  usps: string[];
  energyLabel: EnergyLabel;
  isSet: boolean;
  group: string;
  details: Detail[];
  rrp: RRP;
  components: any[];
  highlights: Highlight[];
  assets: Asset[];
  accessories: Accessory[];
  efhSalesPrograms: SalesProgram[];
}

interface MadeInGermanyIcon {
  id: number;
  asset_id: string;
  type: string;
  category: string;
  url: string;
}

export enum SalesProgram {
  DLN = 'Gesamtsortiment',
  DMK = 'EXCLUSIV®',
  DFH = 'Full House',
}

export type ShortProductData = Pick<FullProductData, 'title' | 'ean' | 'vib'>;

export interface Cart {
  [catTitle: string]: ShortProductData[];
}
