import { Component, Renderer2 } from '@angular/core';

import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [HeaderComponent, FooterComponent, RouterOutlet],
})
export class AppComponent {
  title = 'bosch-pos';

  constructor(private renderer: Renderer2) {
    this.addDragStartListener();
  }

  addDragStartListener(): void {
    this.renderer.listen('document', 'dragstart', (event) => {
      event.preventDefault();
    });
  }
}
