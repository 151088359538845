<header class="mb-4 mt-10 flex w-full flex-col gap-16">
  <img class="absolute left-0 top-0 max-h-2 w-full" src="../../../assets/img/header-rgb.png" />

  <div class="flex justify-between">
    <img
      class="min-w-28 max-w-28 cursor-pointer"
      src="../../../assets/img/bosch_logo_red.svg"
      [routerLink]="['/home']"
    />

    <p class="font-bold">Hausgeräte</p>
  </div>
  <div class="flex-between flex font-bosch">
    <a
      class="inline cursor-pointer pr-16"
      routerLinkActive="underline font-boschbold"
      [routerLinkActiveOptions]="{ exact: true }"
      queryParamsHandling="preserve"
      [routerLink]="['/home']"
      >Startseite</a
    >
    <a class="inline cursor-pointer" routerLinkActive="underline font-boschbold" [routerLink]="['/vergleich']"
      >Vergleich</a
    >
  </div>
</header>
