export type ProductFilter = {
  [key: string]: string[] | number[];
};

export type ProductChild = {
  name: string;
  filters: ProductFilter;
  comment?: string;
};

export type ProductCategory = {
  name: string;
  filters: ProductFilter;
  children: ProductChild[];
};

export type ProductFeature = {
  title: string;
  filterSlug: string;
};

export const productsImageStructure: { [key: string]: string } = {
  'Kochen & Backen': '/assets/img/kochen_backen.jpg',
  'Waschen & Trocknen': '/assets/img/waschen_trocknen.jpg',
  'Geschirrspüler': '/assets/img/geschirrspueler.jpg',
  'Kühlen & Gefrieren': '/assets/img/kuehlen_gefrieren.jpg',
};

export const productsStructure: ProductCategory[] = [
  {
    name: 'Kochen & Backen',
    filters: { brandId: ['A01'], sales_program: ['DLN', 'DMK', 'DFH'] },
    children: [
      {
        name: 'Backöfen (Einbau, 60 cm)',
        filters: { group: ['OVENS'], constr_type: ['CONSTR_TYPE.Built-in'], type_appl_width: [60] },
      },
      {
        name: 'Kompaktgeräte (Einbau, 45 cm und kleiner)',
        filters: { business_areas: ['M30', 'M34', 'M36'] },
        comment: 'https://gitlab.com/lup-projects/bosch-digitaler-katalog-efh/-/issues/5#note_1959926514',
      },
      {
        name: 'Herd- und Backofen Sets',
        filters: { business_areas: ['M70'] },
        comment: 'https://gitlab.com/lup-projects/bosch-digitaler-katalog-efh/-/issues/13',
      },
      {
        name: 'Herde und Mikrowellen (Stand)',
        filters: { group: ['COOKERS', 'MICROWAVE_OVENS'], constr_type: ['CONSTR_TYPE.Free-standing'] },
      },
      {
        name: 'Kochfelder Induktion',
        filters: { group: ['HOBS'], cooker_hob_type: ['COOKER_HOB_TYPE.INDUCTION'] },
      },
      {
        name: 'Kochfelder Strahlung',
        filters: { group: ['HOBS'], cooker_hob_type: ['COOKER_HOB_TYPE.RADIANT'] },
      },
      {
        name: 'Kochfelder mit Dunstabzug',
        filters: { group: ['VENTING_HOBS'] },
      },
      {
        name: 'Dunstabzugshauben',
        filters: { group: ['HOODS'] },
      },
      {
        name: 'Zubehör Kochen & Backen',
        filters: { group: ['ACCESSORIES'], business_areas: ['M51', 'N51', 'P50'] },
        comment: 'https://gitlab.com/lup-projects/bosch-digitaler-katalog-efh/-/issues/13',
      },
    ],
  },
  {
    name: 'Waschen & Trocknen',
    filters: { brandId: ['A01'], sales_program: ['DLN', 'DMK', 'DFH'] },
    children: [
      {
        name: 'Waschmaschinen',
        filters: { group: ['WASHING_MACHINES'] },
      },
      {
        name: 'Trockner',
        filters: { group: ['DRYERS'] },
      },
      {
        name: 'Waschtrockner',
        filters: { group: ['WASHER_DRYERS'] },
      },
      {
        name: 'Zubehör Waschen & Trocknen',
        filters: { group: ['ACCESSORIES'], business_areas: ['G50', 'H50'] },
        comment: 'https://gitlab.com/lup-projects/bosch-digitaler-katalog-efh/-/issues/13',
      },
    ],
  },
  {
    name: 'Geschirrspüler',
    filters: { brandId: ['A01'], sales_program: ['DLN', 'DMK', 'DFH'] },
    children: [
      {
        name: 'Geschirrspüler Stand (60 cm)',
        filters: { group: ['DISHWASHERS'], constr_type: ['CONSTR_TYPE.Free-standing'], type_appl_width: [60] },
      },
      {
        name: 'Geschirrspüler Stand (45 cm)',
        filters: { group: ['DISHWASHERS'], constr_type: ['CONSTR_TYPE.Free-standing'], type_appl_width: [45] },
      },
      {
        name: 'Geschirrspüler Einbau (60 cm)',
        filters: { group: ['DISHWASHERS'], constr_type: ['CONSTR_TYPE.Built-in'], type_appl_width: [60] },
      },
      {
        name: 'Geschirrspüler Einbau (45 cm)',
        filters: { group: ['DISHWASHERS'], constr_type: ['CONSTR_TYPE.Built-in'], type_appl_width: [45] },
      },
      {
        name: 'Geschirrspüler sonstige (z.B. 45 Nische)',
        filters: { business_areas: ['E21'] },
        comment: 'https://gitlab.com/lup-projects/bosch-digitaler-katalog-efh/-/issues/5#note_1959926514',
      },
      {
        name: 'Zubehör Geschirrspülen',
        filters: { group: ['ACCESSORIES'], business_areas: ['E50'] },
        comment: 'https://gitlab.com/lup-projects/bosch-digitaler-katalog-efh/-/issues/13',
      },
    ],
  },
  {
    name: 'Kühlen & Gefrieren',
    filters: { brandId: ['A01'], sales_program: ['DLN', 'DMK', 'DFH'] },
    children: [
      {
        name: 'Kühl- und Gefrierkombinationen',
        filters: { group: ['FRIDGE_FREEZER_COMBINATIONS'] },
      },
      {
        name: 'Kühlgeräte (Stand)',
        filters: { group: ['REFRIGERATORS'], constr_type: ['CONSTR_TYPE.Free-standing'] },
      },
      {
        name: 'Gefriergeräte (Stand)',
        filters: { group: ['FREEZERS'], constr_type: ['CONSTR_TYPE.Free-standing'] },
      },
      {
        name: 'Kühlgeräte (Einbau)',
        filters: { group: ['REFRIGERATORS'], constr_type: ['CONSTR_TYPE.Built-in'] },
      },
      {
        name: 'Gefriergeräte (Einbau)',
        filters: { group: ['FREEZERS'], constr_type: ['CONSTR_TYPE.Built-in'] },
      },
      {
        name: 'Side-by-Side / Multi- Frenchdoor',
        filters: { business_areas: ['A36', 'A17'] },
      },
      {
        name: 'Weinlagerschränke',
        filters: { group: ['WINE_STORAGE'] },
      },
      {
        name: 'Zubehör Kühlen & Gefrieren',
        filters: { group: ['ACCESSORIES'], business_areas: ['A50', 'B50'] },
        comment: 'https://gitlab.com/lup-projects/bosch-digitaler-katalog-efh/-/issues/13',
      },
    ],
  },
];
