<div [class]="'flex gap-4 pt-4 font-bosch' + ' ' + styleClasses">
  @for (checkbox of checkBoxes; track checkbox) {
    <div class="flex items-center gap-2">
      <input
        type="checkbox"
        id="{{ checkbox.name }}"
        class="size-5 cursor-pointer"
        [checked]="checkbox.checked"
        (change)="changeAndStoreUrlQueryParams($event, checkbox)"
      />
      <label for="{{ checkbox.name }}" class="text-xs">{{ checkbox.label }}</label>
    </div>
  }
</div>
