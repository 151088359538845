<div class="mb-4 flex items-center gap-4 py-4">
  <input
    type="checkbox"
    id="cbcompareproduct"
    class="size-5 cursor-pointer"
    (click)="onCheckboxChange()"
    [checked]="cbCompareProduct"
  />
  <label for="cbgeneral" class="text-xs">Produktvergleich</label>
</div>

<div class="flex flex-col gap-y-4">
  <div class="min-h-12">
    @for (salesProgram of product.efhSalesPrograms; track salesProgram) {
      <p>{{ this.helperService.getSalesProgramNameFromShortForm(salesProgram) }}</p>
    }
  </div>
  <div class="flex items-start gap-x-1">
    <img class="max-h-80 max-w-[80%] object-contain" [src]="product.lowResPicture" alt="{{ product.title }}" />

    @if (product.madeInGermanyIcon) {
      <div class="flex flex-col items-end">
        <img class="max-w-10" [src]="product.madeInGermanyIcon.url" alt="icon" />
      </div>
    }
  </div>
  <div class="text-xl">
    <p class="font-bosch">{{ product.vib }}</p>
    <p class="font-boschbold">{{ product.title }}</p>
  </div>

  @if (product.energyLabel) {
    <div><img class="max-w-16" [src]="product.energyLabel.url" alt="icon" /></div>
  }

  <div>
    <p class="text-xl">{{ product.rrp.formatted }}</p>
    <p class="font-bosch text-sm">inkl. MwSt.</p>
  </div>

  <div class="flex cursor-pointer items-center" [routerLink]="['/details']" [queryParams]="{ ean: product.ean }">
    <span class="inline-flex items-center justify-center bg-bosch-blue-light px-5 py-[6px] font-bosch text-white"
      >Produktdetails</span
    >
  </div>
  <div class="hidden md:flex md:flex-col md:gap-4">
    <div>
      <p class="mb-4 font-boschbold text-xl">Highlights</p>
      <ul class="list-[square] pl-4">
        @for (highlight of product.highlights.slice(0, 5); track highlight) {
          <li class="font-bosch text-sm">
            {{ highlight.headline ? highlight.headline.replace(',', ', ').replace('  ', ' ') : 'Keine Information' }}
          </li>
        }
      </ul>
    </div>

    <div class="flex flex-col gap-y-4">
      <p class="font-boschbold text-xl">Produktmerkmale</p>

      @for (detail of product.details.slice(0, 5); track detail) {
        <span>
          <p class="font-boschbold text-sm">{{ detail.headline }}</p>

          @if (detail.lines.length > 0) {
            <div class="flex flex-col">
              @for (line of detail.lines.slice(0, 1); track line) {
                <p class="font-bosch text-sm">
                  {{ line ? line.replace(',', ', ').replace('  ', ' ') : 'Keine Information' }}
                </p>
              }
            </div>
          }
        </span>
      }
    </div>
  </div>
  <details class="mb-12 md:hidden">
    <summary class="text-lg">Mehr Infos anzeigen</summary>
    <div>
      <p class="text-xl">Highlights</p>
      <ul class="list-disc pl-5">
        @for (highlight of product.highlights.slice(0, 5); track highlight) {
          <li class="font-bosch text-sm">
            {{ highlight.headline ? highlight.headline.replace(',', ', ').replace('  ', ' ') : 'Keine Information' }}
          </li>
        }
      </ul>
    </div>

    <div>
      <p class="text-xl">Produktmerkmale</p>

      @for (detail of product.details.slice(0, 5); track detail) {
        <p class="pt-4 text-sm">{{ detail.headline }}</p>

        @if (detail.lines.length > 0) {
          <div class="flex flex-col gap-2">
            @for (line of detail.lines.slice(0, 1); track line) {
              <p class="font-bosch text-sm">
                {{ line ? line.replace(',', ', ').replace('  ', ' ') : 'Keine Information' }}
              </p>
            }
          </div>
        }
      }
    </div>
  </details>
</div>
