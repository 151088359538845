import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: 'root',
})
export class SalesProgramService {
  private checkboxChangeSubject = new Subject<string[]>();
  checkboxChange$ = this.checkboxChangeSubject.asObservable();

  emitCheckboxChange(selectedFilters: string[]) {
    this.checkboxChangeSubject.next(selectedFilters);
  }
}
