import { Injectable } from '@angular/core';
import { ProductCategory, ProductFilter } from '../../assets/data/products-structure';
import { SalesProgram } from '../models/product.model';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  constructor() {}

  findProductCategory(name: string, productsStructure: ProductCategory[]): string {
    for (const category of productsStructure) {
      const foundFeature = category.children.find((child) => child.name === name);
      if (foundFeature) {
        return category.name;
      }
    }
    return 'Vergleichen';
  }

  createApiFilterParams(filter: ProductFilter): string {
    const slugParts: string[] = [];
    for (const key in filter) {
      const values = filter[key];
      const joinedValues = values.join(',');
      slugParts.push(`${key}=${joinedValues}`);
    }

    return slugParts.join('&');
  }

  public isLocalStorageAvailable(): boolean {
    try {
      const testKey = '__test__';
      localStorage.setItem(testKey, testKey);
      localStorage.removeItem(testKey);
      return true;
    } catch (e) {
      return false;
    }
  }

  getSalesProgramFilters(): string[] | void {
    if (this.isLocalStorageAvailable()) {
      return localStorage.getItem('sales_program')?.split(',') || [];
    }
  }

  getSalesProgramNameFromShortForm(shortForm: string): string {
    return SalesProgram[shortForm as keyof typeof SalesProgram];
  }

  parseUrlParamToString(param: any): string {
    if (param == null) {
      return '';
    }
    if (Array.isArray(param)) {
      return param.join(',');
    }
    if (typeof param === 'object') {
      // Handle the case where the parameter might be an object
      return Object.values(param).join(',');
    }
    return param.toString();
  }
}
