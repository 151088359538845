import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductPaginationService {
  private pageChangeSubject = new Subject<number>();
  pageChange$ = this.pageChangeSubject.asObservable();

  emitPageChange(page: number) {
    this.pageChangeSubject.next(page);
  }
}
