import { Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ProductOverviewComponent } from './pages/product-overview/product-overview.component';
import { ProductComparisonComponent } from './pages/product-comparison/product-comparison.component';
import { ProductDetailsComponent } from './pages/product-details/product-details.component';

export const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'produkt-uebersicht', component: ProductOverviewComponent },
  { path: 'vergleich', component: ProductComparisonComponent },
  { path: 'details', component: ProductDetailsComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
];
