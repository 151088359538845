<div class="absolute left-0 top-44 h-12 w-full bg-bosch-grey-light"></div>
<div class="mt-16">
  <div class="mb-16 mt-6 flex justify-between gap-6 pt-4 max-sm:flex-col">
    <h1 class="text-xl md:text-4xl">Produktvergleich</h1>
    <select class="form-control pointer-cursor h-fit min-w-60 bg-bosch-grey-light p-2" (change)="changeCart($event)">
      @for (category of categories; track category) {
        <option [value]="category" [selected]="category === selectedCategory">{{ category }}</option>
      }
    </select>
  </div>

  @if (productsToCompare.length > 0) {
    <div #productsContainer class="flex gap-8 overflow-x-auto md:ml-44" (scroll)="syncScroll($event)">
      @for (product of productsToCompare; track product) {
        <div class="relative flex min-w-44 max-w-44 flex-1 flex-shrink-0 flex-col justify-end">
          <div class="flex h-full justify-between gap-4">
            <div class="flex justify-end">
              <img class="max-h-96 self-end" [src]="product.medResPicture" alt="{{ product.title }}" />
            </div>
            <img
              (click)="removeVibAndGetProducts(product.vib)"
              class="size-8 cursor-pointer self-start"
              src="./../../../assets/icons/close.svg"
              alt="close"
            />
          </div>
          <div>
            <div class="mt-4 min-h-24">
              <p class="font-bosch">{{ product.vib }}</p>
              <p class="truncate-3lines">{{ product.title }}</p>
            </div>

            <div class="my-4 flex items-center gap-4">
              @if (product.madeInGermanyIcon) {
                <img class="h-8" [src]="product.madeInGermanyIcon.url" alt="" />
              }

              @if (product.energyLabel) {
                <img class="h-8" [src]="product.energyLabel.url" alt="" />
              }
            </div>

            <p class="text-lg">{{ product.rrp.formatted }}</p>
            <span class="font-bosch">inkl. MwSt.</span>
          </div>
        </div>
      }
    </div>
    <div class="mt-4 flex cursor-pointer items-center border-b border-gray-300 py-4">
      <h2 class="min-w-44 max-w-44 text-xl">Sortiment</h2>
      <div #accordionContainer class="hidden gap-8 overflow-x-auto py-4 md:flex" (scroll)="syncScroll($event)">
        @for (product of productsToCompare; track product) {
          <div class="min-w-44 max-w-44 flex-1 flex-shrink-0">
            <span class="font-bosch text-sm">
              @for (salesProgram of product.efhSalesPrograms; track salesProgram) {
                {{ this.helperService.getSalesProgramNameFromShortForm(salesProgram) }}
              }
            </span>
          </div>
        }
      </div>
    </div>

    <div #accordionContainer class="flex gap-8 overflow-x-auto py-4 md:hidden" (scroll)="syncScroll($event)">
      @for (product of productsToCompare; track product) {
        <div class="min-w-44 max-w-44 flex-1 flex-shrink-0">
          <span class="font-bosch text-sm">
            @for (salesProgram of product.efhSalesPrograms; track salesProgram) {
              {{ this.helperService.getSalesProgramNameFromShortForm(salesProgram) }}
            }
          </span>
        </div>
      }
    </div>

    <h2 class="mt-6 text-xl">Allgemein</h2>
    @for (item of mappedProductDetails; track item; let i = $index) {
      <div class="mt-4">
        <div class="mb-4">
          <div
            class="flex cursor-pointer justify-between border-b border-gray-300 py-4"
            (click)="toggleAccordion(item.headline)"
          >
            <h2 class="text-md">{{ item.headline }}</h2>

            <img
              [ngClass]="{ 'rotate-180': isOpen(item.headline) }"
              class="w-5"
              src="./../../../assets/icons/arrow-gray.svg"
              alt="pinterest"
            />
          </div>
          @if (isOpen(item.headline)) {
            <div #accordionContainer class="flex gap-8 overflow-x-auto py-4 md:ml-44" (scroll)="syncScroll($event)">
              @for (linesGroup of item.lines; track linesGroup) {
                <ul class="min-w-44 max-w-44 flex-1 flex-shrink-0 list-[square] pl-4 font-bosch">
                  @for (line of linesGroup; track line) {
                    <li class="text-sm">
                      {{ line }}
                    </li>
                  }
                </ul>
              }
            </div>
          }
        </div>
      </div>
    }
  } @else {
    <p class="font-bosch">Keine Produkte zum Vergleichen ausgewählt.</p>
  }
</div>
