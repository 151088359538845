import { Location } from '@angular/common';
import { Component, OnInit, AfterViewInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
})
export class ProductDetailsComponent implements OnInit, AfterViewInit {
  ean = '';
  reloaded = false;

  constructor(
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private location: Location,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.ean = params['ean'] || null;
    });
  }

  ngAfterViewInit(): void {
    if (typeof window !== 'undefined') {
      this.loadLoadbeeScript();
    }
  }

  private loadLoadbeeScript(): void {
    const script = this.renderer.createElement('script');
    script.src = 'https://cdn.loadbee.com/js/loadbee_integration.js';
    script.async = true;
    this.renderer.appendChild(document.body, script);
  }

  goBack(): void {
    this.location.back();
  }
}
