@if (meta && meta.last_page > 1) {
  <div class="flex items-center justify-center font-bosch">
    <div
      [class.pointer-events-none]="meta.current_page === 1"
      (click)="meta.current_page > 1 && changePage(meta.current_page - 1)"
      class="-rotate-180 cursor-pointer"
    >
      @if (meta.current_page === 1) {
        <img class="size-8 opacity-20" src="/../../../assets/icons/wide-angled-arrow.svg" />
      } @else {
        <img class="size-8" src="/../../../assets/icons/wide-angled-arrow.svg" />
      }
    </div>
    <!-- Pagination Items -->
    @if (meta.last_page > 1) {
      @for (page of getPaginationItems(); track page) {
        @if (page === meta.current_page) {
          <div
            (click)="changePage(page)"
            class="m-2 flex size-8 cursor-pointer items-center justify-center rounded-full border-b bg-bosch-blue-dark text-white"
          >
            {{ page }}
          </div>
        } @else {
          <div
            (click)="changePage(page)"
            class="m-2 flex size-8 cursor-pointer items-center justify-center rounded-full bg-bosch-grey-light"
          >
            {{ page }}
          </div>
        }
      }

      @if (meta.current_page < meta.last_page) {
        <div class="cursor-pointer" (click)="changePage(meta.current_page + 1)">
          <img class="size-8" src="./../../../assets/icons/wide-angled-arrow.svg" alt="forward" />
        </div>
      } @else if (meta.current_page === meta.last_page) {
        <div
          [class.pointer-events-none]="meta.current_page === meta.last_page"
          class="cursor-pointer"
          (click)="changePage(meta.current_page + 1)"
        >
          <img class="size-8 opacity-20" src="./../../../assets/icons/wide-angled-arrow.svg" alt="forward" />
        </div>
      }
    }
  </div>
}
