import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { Cart, ShortProductData } from '../models/product.model';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: 'root',
})
export class CompareService {
  private carts$: BehaviorSubject<Cart> = new BehaviorSubject<Cart>({});
  private readonly STORAGE_KEY = 'comparedProducts';

  constructor(private helperService: HelperService) {
    const savedProducts = this.loadProductsToCompareFromLocalStorage();
    this.carts$ = new BehaviorSubject<Cart>(savedProducts);

    this.carts$.subscribe((products) => {
      this.saveProductsToCompareToLocalStorage(products);
    });
  }

  getProductsByCategoryTitle(catTitle: string): Observable<ShortProductData[]> {
    return this.carts$.pipe(map((carts) => carts[catTitle] || []));
  }

  getProductCategoriesInCart() {
    const cart = this.carts$.getValue();
    return Object.keys(cart);
  }

  addProductToCart(catTitle: string, product: ShortProductData): void {
    const currentCarts = this.carts$.value;
    const updatedCarts = {
      ...currentCarts,
      [catTitle]: [...(currentCarts[catTitle] || []), product],
    };
    this.carts$.next(updatedCarts);
  }

  removeProductFromCart(catTitle: string, product: ShortProductData): void {
    const currentCarts = this.carts$.value;
    if (currentCarts[catTitle]) {
      const updatedProductsForTitle = currentCarts[catTitle].filter((prod) => prod.ean !== product.ean);
      const updatedCarts = {
        ...currentCarts,
        [catTitle]: updatedProductsForTitle,
      };
      if (updatedProductsForTitle.length === 0) {
        delete updatedCarts[catTitle];
      }
      this.carts$.next(updatedCarts);
    }
  }

  isProductInComparison(catTitle: string, product: ShortProductData): Observable<boolean> {
    return this.carts$.pipe(map((carts) => (carts[catTitle] || []).some((p) => p.ean === product.ean)));
  }

  private saveProductsToCompareToLocalStorage(carts: Cart): void {
    if (this.helperService.isLocalStorageAvailable()) {
      localStorage.setItem(this.STORAGE_KEY, JSON.stringify(carts));
    }
  }

  private loadProductsToCompareFromLocalStorage(): Cart {
    if (this.helperService.isLocalStorageAvailable()) {
      const savedProducts = localStorage.getItem(this.STORAGE_KEY);
      return savedProducts ? JSON.parse(savedProducts) : {};
    }
    return {};
  }
}
